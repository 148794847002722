<template>
  <div>
    <div class="overviewTop row no-gutters">
      <div class="col mr-1">
        <el-card shadow="hover" header="预付款账户（元）" class="x-card-title tipBox">
          <div class="ye">
            <div class="right">
              <span>账户余额预警</span>
              <el-switch v-model="detail.warning_status !== 0" @change="swit"></el-switch>
            </div>
            <span class="price" style="color: rgba(255, 21, 34, 1)">
              {{ detail.balance_account || 0 }}
            </span>
          </div>
          <div>
            <div class="right">
              <span>预警值为：{{ (detail.warning_money || 0) | unitPrice('￥') }}</span>
              <el-button @click="yjShow = true" type="text">修改</el-button>
            </div>
            <el-button @click="addMoney" size="small" type="primary">
              充值
            </el-button>
          </div>
        </el-card>
      </div>

      <div class="col ml-1">
        <el-card shadow="hover" header="站点现金账户总额（元）" class="x-card-title tipBox">
          <div class="ye">
            <div class="right" style="opacity: 0">
              <span>账户余额预警</span>
              <el-switch v-model="detail.warning_status !== 0" @change="swit"></el-switch>
            </div>
            <span class="price" style="color: rgba(0, 214, 0, 1)">
              {{ detail.cash_account || 0 }}
            </span>
          </div>

          <el-button @click="manage" type="primary" size="small">管理</el-button>
        </el-card>
      </div>
    </div>

    <el-card shadow="hover" class="x-card-title-slot mt-2">
      <div slot="header" class="header-title">
        <span class="cursor" :style="tt === 1 ? 'color:blue' : ''" @click="getRi">日收入</span>
        |
        <span class="cursor" @click="getYue" :style="tt === 2 ? 'color:blue' : ''">
          月收入
        </span>
      </div>

      <div class="row no-gutters">
        <div class="box first-child col">
          <div>
            <p>
              <span>收入总计：</span>
              <span class="red span_font" style="color: #ff1521">
                {{ (gailan.income || 0) | unitPrice('') }}
              </span>
            </p>
            <p>
              <span>充值总计：</span>
              <span class="span_font">+{{ (gailan.recharge_all || 0) | unitPrice('') }}</span>
            </p>
            <el-button @click="go1" type="text">查看更多</el-button>
          </div>
        </div>
        <div class="box last-child col">
          <div>
            <p>
              <span>支出总计：</span>
              <span class="red span_font" style="color: #ff1521">
                {{ (gailan.outcome || 0) | unitPrice('') }}
              </span>
            </p>
            <p>
              <span>商品采购：</span>
              <span class="span_font">+{{ (gailan.buy_all || 0) | unitPrice('') }}</span>
            </p>
            <p>
              <span>订单服务费：</span>
              <span class="span_font">
                {{ (gailan.service_all || 0) | unitPrice('') }}
              </span>
            </p>
            <el-button @click="go2" type="text">查看更多</el-button>
          </div>
        </div>
      </div>
    </el-card>

    <div class="row no-gutters align-items-start mt-2 text-center">
      <el-card shadow="hover" class="col mr-2">
        <img src="../../assets/setting.png" />
        <h3>资金流水</h3>
        <div class="linkAll">
          <router-link to="/finance/finance-summary">账单汇总</router-link>
          |
          <router-link to="/finance/finance-detailed">财务明细</router-link>
        </div>
      </el-card>
      <el-card shadow="hover" class="col mr-2">
        <img src="../../assets/zdcw.png" />
        <h3>站点财务</h3>
        <div class="linkAll">
          <router-link to="/finance/site-finance?type=first">站点账户管理
          </router-link>
          |
          <router-link to="/finance/site-finance?type=second">站点交易明细
          </router-link>
        </div>
      </el-card>
      <el-card shadow="hover" class="col">
        <img src="../../assets/shezhi.png" />
        <h3>设置</h3>
        <div class="linkAll">
          <router-link to="/finance/account">结算账户</router-link>
        </div>
      </el-card>
    </div>

    <!--预存预付款-->
    <el-dialog title="充值" :visible.sync="isShowDisRebate">
      <el-form :model="disRebateData" label-width="100px" :rules="disRules" ref="disRebateData" status-icon>
        <el-form-item label="公司名称">
          <span>{{ disRebateData.shop_name || '' }}</span>
        </el-form-item>
        <el-form-item label="预存金额" prop="price">
          <el-input v-model="disRebateData.price" style="width: 250px" placeholder="起充金额为100元，请输入100以上的金额"></el-input>
        </el-form-item>
        <el-form-item label="支付方式" prop="img">
          <el-radio-group v-model="disRebateData.payment_plugin">
            <el-radio label="1">
              <img src="../../assets/images/wx.jpg" style="width: 100px; height: 50px" />
            </el-radio>
            <!--  <el-radio label="2">
                <img src="../../assets/images/nowx.jpg" style="width:100px;height:50px;" />
            </el-radio>-->
            <el-radio label="3">
              <img src="../../assets/images/underlinepay.png" style="width: 100px; height: 50px" />
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="凭证图片" prop="pay_img" v-if="disRebateData.payment_plugin == 3">
          <el-upload class="site-logo" :action="MixinUploadApi" :show-file-list="false" :on-success="(res) => {
                  disRebateData.pay_img = res.url;
                }
                " :multiple="false">
            <img v-if="disRebateData.pay_img" :src="disRebateData.pay_img" class="site-logo-img" />
            <i v-else class="el-icon-plus logo-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" @click="lijiApply('disRebateData')">立即支付
          </el-button>
        </el-form-item>
      </el-form>
      <div v-show="showPayBox && disRebateData.payment_plugin == 1" class="cashier-pay-box">
        <div class="pay-item">
          <div class="pay-left" v-if="payment_plugin_id === 'accountPayPlugin'">
            <p>使用账户余额支付</p>
            <div class="pc-pay-img"></div>
          </div>
          <div class="pay-left" v-else>
            <p v-if="payment_plugin_id !== 'weixinPayPlugin'">使用电脑支付</p>
            <div v-if="payment_plugin_id === 'weixinPayPlugin'" class="pc-pay-img">
              <img src="../../assets/images/background-wechat.jpg" />
            </div>
            <div v-else class="pc-pay-img"></div>
            <!-- 	<a class="pay-btn" href="javascript:;" @click="initiatePay(false, 'normal')">立即支付</a> -->
            <i v-if="payment_plugin_id === 'alipayDirectPlugin'" class="icon-or"></i>
          </div>
          <div v-if="payment_plugin_id === 'alipayDirectPlugin' ||
                payment_plugin_id === 'weixinPayPlugin'
                " class="pay-right">
            <p v-if="payment_plugin_id === 'alipayDirectPlugin'">
              使用支付宝钱包扫一扫即可付款
            </p>
            <p v-if="payment_plugin_id === 'weixinPayPlugin'">
              使用微信钱包扫一扫即可付款
            </p>
            <div class="pay-qrcode" id="pay-qrcode">
              <iframe id="iframe-qrcode" width="200px" height="200px" scrolling="no" frameborder="0"></iframe>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>

    <!--预警值设置-->
    <el-dialog title="预警值设置" :visible.sync="yjShow" width="600px">
      <div class="addMealForm">
        <div class="yjTip">
          注意：当账户余额低于该值时，会每天发送站内信提醒一次。
        </div>
        <el-form :model="yjInfo" ref="yjInfo" :rules="yjRules" label-width="185px">
          <el-form-item class="w200" label="预警值设置：" prop="a3a3">
            <el-input-number style="width: 200px" :controls="false" v-model="yjInfo.a3a3" :min="0"></el-input-number>
            元
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="yjShow = false">取消</el-button>
          <el-button type="primary" @click="setYuJing">确定</el-button>
        </div>
      </div>
    </el-dialog>

    <!--管理-->
    <el-dialog title="管理" :visible.sync="glShow" width="1100px">
      <div class="glMoney">
        <div class="top">
          <div class="left">
            <h3>代收现金账户总额</h3>
            <h4>{{ detail.cash_account ? detail.cash_account : '0.00' }}</h4>
          </div>
          <div class="right">
            <h3>锁定金额</h3>
            <h5>{{ detail.lock_account ? detail.lock_account : '0.00' }}</h5>
            <h3>可提现金额</h3>
            <h6>
              <div class="btnRight">
                <el-button type="primary" @click="zzShow = true">
                  转至账户
                </el-button>
                <el-button type="default" @click="txShow = true">
                  提现
                </el-button>
              </div>
              {{ detail.un_account ? detail.un_account : '0.00' }}
            </h6>
          </div>
        </div>
        <div class="tip">
          <p>温馨提示</p>
          <p>
            1.提现只能提现至会员账户下与实名认证信息同名账户的支付宝或银行卡；
          </p>
          <p>
            2.提现至会员银行卡或支付宝需3个工作日审核，提现至会员账户实时单到账；
          </p>
          <p>3.支付宝方式提现实时到账；银行卡提现预计3个工作日到账；</p>
          <p>
            4.提现至会员账户，无金额限制；提现至银行卡或支付宝需站点现金额账户总金额
            ≥500元。
          </p>
        </div>
      </div>

      <en-table-layout :tableData="glList.data" border>
        <template slot="toolbar">
          <el-form-item label="申请时间">
            <el-date-picker :default-time="['00:00:00', '23:59:59']" value-format="timestamp" style="width: 220px"
              v-model="daterangeDate" type="daterange" @change="daterangeDateChange" range-separator="-"
              start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
          </el-form-item>

          <el-form-item label="完成时间">
            <el-date-picker :default-time="['00:00:00', '23:59:59']" value-format="timestamp" style="width: 220px"
              v-model="dateEnd" type="daterange" @change="daterangeDateChange1" range-separator="-"
              start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
          </el-form-item>

          <el-form-item label="">
            <el-select style="width: 150px !important" clearable v-model="params2.out_way" placeholder="提现方式">
              <el-option v-for="item in list1" :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="">
            <el-select style="width: 150px !important" clearable v-model="params2.pass" placeholder="提现状态">
              <el-option v-for="item in list2" :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </el-form-item>

          <el-button type="primary" size="small" :loading="importLoading" @click="getList()">
            搜索
          </el-button>
        </template>

        <template slot="table-columns">
          <el-table-column label="流水号" prop="sn"></el-table-column>
          <el-table-column label="提现方式">
            <template slot-scope="scope">
              {{
                scope.row.out_way === 0
                  ? '线下充值'
                  : scope.row.out_way === 1
                    ? '转至会员账户'
                    : scope.row.out_way === 2
                      ? '转至支付宝'
                      : '转至银行卡'
              }}
            </template>
            <!--  0：线下汇款 1：转至会员账户，2：转至支付宝，3：转至银行卡 -->
          </el-table-column>
          <el-table-column label="提现金额">
            <template slot-scope="scope">{{ scope.row.cash_money }}</template>
          </el-table-column>
          <el-table-column label="手续费">
            <template slot-scope="scope">
              {{ scope.row.fee_money | unitPrice('￥') }}
            </template>
          </el-table-column>
          <el-table-column label="站点数">
            <template slot-scope="scope">
              {{ scope.row.child_num ? scope.row.child_num : 0 }}
            </template>
          </el-table-column>
          <el-table-column label="状态" prop="pass" :formatter="handlepass"></el-table-column>
          <el-table-column label="申请提现时间" width="160">
            <template slot-scope="scope">
              {{ scope.row.create_time | unixToDate('yyyy-MM-dd hh:mm:ss') }}
            </template>
          </el-table-column>
          <el-table-column label="操作完成时间" width="160">
            <template slot-scope="scope">
              {{ scope.row.complete_time | unixToDate('yyyy-MM-dd hh:mm:ss') }}
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button size="mini" type="primary" @click="handleTxxq(scope.row)">
                详情
              </el-button>
            </template>
          </el-table-column>
        </template>
        <el-pagination slot="pagination" @size-change="handlePageSizeChange1" @current-change="handlePageCurrentChange1"
          :current-page="params2.page_no" :page-size="params2.page_size" :page-sizes="MixinPageSizes"
          :layout="MixinTableLayout" background :total="glList.data_total"></el-pagination>
      </en-table-layout>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="glShow = false">关闭</el-button>
      </div>
    </el-dialog>

    <!--提现-->
    <el-dialog title="提现" :visible.sync="txShow" width="600px">
      <div class="addMealForm">
        <el-form :model="txInfo" ref="txInfo" :rules="txRules" label-width="135px">
          <el-form-item class="w200" label="提现金额：" prop="a7a7">
            <el-input-number style="width: 200px" :controls="false" v-model.number="txInfo.a7a7"
              :min="0"></el-input-number>
            元
          </el-form-item>
          <el-form-item label="请选择提现方式：" prop="b7b7">
            <el-radio-group v-model="txInfo.b7b7">
              <el-radio :label="2">支付宝</el-radio>
              <el-radio :label="3">银行卡</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="提现账户：" prop="c7c7">
            <el-select style="width: 400px" v-model="txInfo.c7c7" placeholder="请选择提现账户">
              <el-option v-for="item in list3" :key="item.id" :label="item.account_num" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="default" @click="txShow = false">取消</el-button>
        <el-button type="primary" @click="tranLateMoney(txInfo.b7b7, 'txInfo')">
          确定
        </el-button>
      </div>
    </el-dialog>
    <!--付款-->
    <el-dialog title="付款" :visible.sync="pShow" width="600px">
      <div>
        <img :src="payUrl" />
      </div>
    </el-dialog>

    <!--转账-->
    <el-dialog title="转账" :visible.sync="zzShow" width="600px">
      <div class="addMealForm">
        <el-form :model="zzInfo" ref="zzInfo" :rules="zzRules" label-width="135px">
          <el-form-item class="w200" label="转账金额：" prop="a7a7">
            <el-input-number style="width: 200px" :controls="false" v-model="zzInfo.a7a7" :min="0"></el-input-number>
            <el-button class="t1" type="default" @click="zzInfo.a7a7 = detail.un_account ? detail.un_account : ''">
              全部金额
            </el-button>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="default" @click="zzShow = false">取消</el-button>
        <el-button type="primary" @click="tranLateMoney(1, 'zzInfo')">
          确定
        </el-button>
      </div>
    </el-dialog>

    <!--提现详情-->
    <el-dialog title="提现详情" :visible.sync="txxqShow" width="800px">
      <div class="txxqMoney">
        <div class="left">
          <p>
            交易状态：
            <span :class="txxqObj.pass == 3 ? 'green' : txxqObj.pass == 2 ? 'red' : ''
                ">{{ handlepass(txxqObj) }}</span>
            <!-- <span class="red">提现失败</span> -->
            <!-- <span>审核中</span> -->
          </p>
          <p>流水号：{{ txxqObj.sn }}</p>
          <p>开户名：{{ txxqObj.shop_name }}</p>
          <p>提现金额：{{ txxqObj.cash_money | unitPrice('￥') }}</p>
          <p>提现手续费：{{ txxqObj.fee_money | unitPrice('￥') }}</p>
          <p>
            申请时间：{{
                txxqObj.create_time | unixToDate('yyyy-MM-dd hh:mm:ss')
              }}
          </p>
        </div>
        <div class="right">
          <p></p>
          <p>提现方式：{{ handleOutwey(txxqObj) }}</p>
          <p>提现账号：{{ txxqObj.account_num }}</p>
          <p>到账金额：{{ txxqObj.cash_arrive_money | unitPrice('￥') }}</p>
          <p>
            包含站点数：共{{ txxqObj.child_num ? txxqObj.child_num : 0 }}个站点
          </p>
          <p>
            完成时间：{{
                txxqObj.complete_time | unixToDate('yyyy-MM-dd hh:mm:ss')
              }}
          </p>
        </div>
      </div>
      <div class="titleTop">
        <p>站点明细</p>
      </div>
      <en-table-layout :toolbar="false" :tableData="txxqList" border>
        <template slot="table-columns">
          <el-table-column label="序号">
            <template slot-scope="scope">{{ scope.row.id }}</template>
          </el-table-column>
          <el-table-column label="站点名称">
            <template slot-scope="scope">{{ scope.row.shop_name }}</template>
          </el-table-column>
          <!-- <el-table-column label="站点类型">
            <template slot-scope="scope">{{
              scope.row.mall_type_name
            }}</template>
          </el-table-column> -->
          <el-table-column label="提现金额">
            <template slot-scope="scope">￥{{ scope.row.cash_money }}</template>
          </el-table-column>
          <el-table-column label="提现后余额">
            <template slot-scope="scope">￥{{ scope.row.cash_account }}
            </template>
          </el-table-column>
        </template>
      </en-table-layout>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="txxqShow = false">关闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import * as API_account from '@/api/account';
import * as API_logistics from '@/api/expressCompany';
import { handleDownload } from '@/utils';
import { Foundation, RegExp } from '@/../ui-utils';
import EnTableLayout from '../../../ui-components/TableLayout/src/main';

export default {
  name: 'goodsList',
  components: { EnTableLayout },
  data () {
    const checkMoney = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('预存金额不能为空'));
      }
      setTimeout(() => {
        if (!RegExp.money.test(value)) {
          callback(new Error('请输入正确的金额'));
        } else if (parseFloat(value) < 0.01) {
          callback(new Error('请输入100以上的金额'));
        } else {
          callback();
        }
      }, 500);
    };
    const checkRadio = (rule, value, callback) => {
      console.log(rule, value, callback);
      if (value < 1) {
        return callback(new Error('请选择支付方式'));
      } else {
        callback();
      }
    };
    return {
      payUrl: '',
      yjShow: false,
      czShow: false,
      glShow: false,
      tipBool: false,
      gailan: {},
      pShow: false,
      txShow: false,
      txxqList: [],
      zzShow: false,
      txxqShow: false,
      /** 列表loading状态 */
      loading: false,
      importLoading: false,
      idsList: [],
      zzInfo: {},
      zzRules: {
        a8a8: [{
          required: true,
          message: '请输入转账金额',
          trigger: 'blur'
        }],
      },
      txInfo: { b7b7: 3 },
      txRules: {
        a7a7: [
          {
            validator: (rule, value, callback) => {
              console.log(rule, value);
              if (!value) {
                return callback(new Error('提现金额不能为空'));
              }
              setTimeout(() => {
                if (!RegExp.money.test(value)) {
                  callback(new Error('请输入正确的金额'));
                } else {
                  callback();
                }
              }, 500);
            },
            trigger: 'blur',
          },
          {
            required: true,
            message: '请输入提现金额',
            trigger: 'blur'
          },
        ],
        b7b7: [
          {
            required: true,
            message: '请选择提现方式',
            trigger: 'change'
          },
        ],
        c7c7: [
          {
            required: true,
            message: '请选择提现账户',
            trigger: 'change'
          },
        ],
      },
      czInfo: {
        b5b5: 1,
      },
      yjInfo: {},
      yjRules: {
        a3a3: [{
          required: true,
          message: '请输入预警值',
          trigger: 'blur'
        }],
      },
      glInfo: {},

      /** 列表参数 */
      params: {},

      glList: [{}],

      list1: [
        // { value: 0, label: "线下汇款" },
        {
          value: 1,
          label: '转至会员账户'
        },
        {
          value: 2,
          label: '转至支付宝'
        },
        {
          value: 3,
          label: '转至银行卡'
        },
      ],
      list2: [
        {
          value: 0,
          label: '待审核'
        },
        {
          value: 1,
          label: '提现成功'
        },
        {
          value: 2,
          label: '提现失败'
        },
      ],
      list3: [],
      // 提现详情数据
      txxqObj: {},
      daterangeDate: [],
      dateEnd: [],

      /** 列表数据 */
      tableData: {},

      /** 列表分页数据 */
      pageData: [],

      /** 快递列表 */
      logiList: [],

      /** 店铺信息 */
      shopInfo: this.$store.getters.shopInfo,
      detail: '',
      params1: {
        shop_id: '',
        warning_money: '',
        warning_status: '',
      },
      params2: {
        account_type: 3,
        page_no: 1,
        page_size: 20,
        create_start_time: '',
        create_end_time: '',
        complete_start_time: '',
        complete_end_time: '',
        out_way: '',
        pass: '',
      },
      tt: 1,

      showPayBox: false,
      payment_plugin_id: 'weixinPayPlugin',
      isShowDisRebate: false,
      gateway_url: '',
      /** 充值数据 */
      disRebateData: {
        shop_name: '',
        /** 充值价格 */
        price: '',

        /** 备注 */
        marks: '',
        /** 支付方式 */
        payment_plugin: 0,
        /** 服务类型  */
        service_type: 5,
        /**线下支付图片*/
        pay_img: '',
      },
      /** 分销返利校验规则 */
      disRules: {
        price: [
          {
            required: true,
            message: '预存金额不能为空',
            trigger: 'blur'
          },
          {
            validator: checkMoney,
            trigger: 'blur'
          },
        ],
        payment_plugin: [
          {
            required: true,
            message: '支付方式不能为空',
            trigger: 'blur'
          },
          {
            validator: checkRadio,
            trigger: 'blur'
          },
        ],
        pay_img: [
          {
            required: true,
            message: '图片不能为空',
            trigger: 'blur'
          },
        ]
      },
    };
  },
  filters: {},
  activated () {
  },
  mounted () {
    //获取支付详情
    this.getAcountMoney();
    //获得提现记录列表
    this.txHistList();
    //获取提现账户列表
    this.getXlist();
    //获取首页概览
    this.getGaiLan();
  },
  watch: {
    'txInfo.b7b7': {
      //深度监听，可监听到对象、数组的变化
      handler (val, oldVal) {
        //获取提现账户列表
        this.getXlist();
      },
      deep: true, //true 深度监听
    },
  },
  methods: {
    // 提现详情
    handleTxxq (row) {
      API_account.getBalanceDetail(row.id).then((res) => {
        console.log(res);
        this.txxqObj = res;
        this.txxqShow = true;
      });
      let para = {
        out_id: row.id,
        page_no: 0,
        page_size: 0,
      };
      API_account.getCashOutLogList(para).then((res) => {
        this.txxqList = res.data;
      });
    },
    handleOutwey (row) {
      for (var i = 0; i < this.list1.length; i++) {
        if (this.list1[i].value === row.out_way) {
          return this.list1[i].label;
        }
      }
    },
    handlepass (row) {
      for (var i = 0; i < this.list2.length; i++) {
        if (this.list2[i].value === row.pass) {
          return this.list2[i].label;
        }
      }
      // this.list2.map(item => {
      //   if (item.value === row.pass) return item.label;
      // });
    },
    getRi () {
      this.tt = 1;
      this.getGaiLan();
    },
    getYue () {
      this.tt = 2;
      this.getGaiLan();
    },
    getGaiLan () {
      let pa = {
        coll_type: this.tt,
      };
      API_account.getGaiLan(pa).then((res) => {
        console.log(res, '概览');

        this.gailan = res;
      });
    },
    getList () {
      console.log(this.dateEnd, '时间');

      this.txHistList();
    },
    setYuJing () {
      this.yjShow = false;
      this.params1.shop_id = this.detail.shop_id;

      this.params1.warning_money = this.yjInfo.a3a3 || 0;
      this.params1.warning_status = this.detail.warning_status;
      this.editShopYuJing(this.params1);
    },
    swit () {
      if (this.detail.warning_status == 0) {
        this.detail.warning_status = 1;
      } else {
        this.detail.warning_status = 0;
      }
      this.params1.shop_id = this.detail.shop_id;
      this.params1.warning_money = this.detail.warning_money || 0;
      this.params1.warning_status = this.detail.warning_status;
      this.editShopYuJing(this.params1);
    },
    editShopYuJing (params) {
      API_account.editShopYuJing(params).then((res) => {
        this.detail.warning_money = this.yjInfo.a3a3;
        this.$message.success('保存设置成功');
      });
    },
    getXlist () {
      let params = {
        // 2  为支付宝
        out_type: this.txInfo.b7b7 === 2 ? 2 : 1,
      };
      API_account.getXlist(params).then((response) => {
        this.list3 = response;
      });
    },
    manage () {
      this.glShow = true;
    },
    txHistList () {
      let params = this.params2;
      API_account.getBalanceList(params).then((response) => {
        console.log(response, '提现列表');
        this.glList = response;
      });
    },
    tranLateMoney (way, formName) {
      // txInfo
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let params = {
            out_way: way, //提现方式
            cash_money:
              formName === 'txInfo' ? this.txInfo.a7a7 : this.zzInfo.a7a7, //提现金额
            account_id: this.txInfo.c7c7,
          };
          API_account.tranLateMoney(params).then((res) => {
            this.zzShow = false;
            this.txShow = false;
            this.getList();
            this.getAcountMoney()
          });
        } else {
          return false;
        }
      });
    },
    handleSuccess (res) {
      console.log(res, '上传的图片地址');
      this.czInfo.url = res.url;
    },
    payMoney () {
      this.createOrder();
    },
    go1 () {
      if (this.tt === 1) {
        //日汇总
        this.$router.push('/finance/finance-summary?types=day');
      } else {
        //月汇总
        this.$router.push('/finance/finance-summary?types=mouth');
      }

      // /finance/finance-detailed   /finance/finance-summary
    },
    go2 () {
      if (this.tt === 1) {
        //日汇总
        this.$router.push('/finance/finance-detailed?types=day');
      } else {
        //月汇总
        this.$router.push('/finance/finance-detailed?types=mouth');
      }
    },
    getPayType (type) {
      let plugsId = '';
      if (type == 1) {
        plugsId = 'weixinPayPlugin';
      }
      if (type == 2) {
        plugsId = 'alipayDirectPlugin';
      }
      if (type == 3) {
        plugsId = 'shopCashPlugin';
      }
      return plugsId;
    },
    handleRemove () {
    },
    // --------充值--------
    addMoney (row) {
      this.disRebateData.price = '';
      this.disRebateData.payment_plugin = 0;
      this.disRebateData.service_type = 3;
      this.disRebateData.pay_img = '';
      this.disRebateData.marks = '';
      this.disRebateData.shop_name = this.detail.shop_name;
      this.disRebateData.shop_id = this.detail.shop_id;
      this.isShowDisRebate = true;
      this.showPayBox = false;
    },
    lijiApply (formName) {
      if (this.disRebateData.payment_plugin === 0) {
        this.$message.error('请选择支付方式');
        return;
      }
      if (
        this.disRebateData.payment_plugin === 3 &&
        this.disRebateData.pay_img == ''
      ) {
        this.$message.error('请上传凭证图片');
        return;
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          var obj = {};
          obj.price = this.disRebateData.price;
          obj.service_type = this.disRebateData.service_type;
          // obj.shop_id = this.disRebateData.shop_id;
          obj.marks = this.disRebateData.marks;
          obj.pay_img = this.disRebateData.pay_img;

          // 创建服务订单
          API_account.createOrder(obj).then((response) => {
            console.log(
              'this.disRebateData.payment_plugin=' +
              this.disRebateData.payment_plugin
            );
            // this.isShowDisRebate = false;
            // this.$message.success("当前商品分销返利金额修改成功");
            if (this.disRebateData.payment_plugin == 1) {
              // 获取支付的二维码
              this.weixinPayPlugin(response);
            } else if (this.disRebateData.payment_plugin == 2) {
              //余额支付
              this.shopBalancePlugin(response);
            } else {
              //线下支付
              this.payDown(response);
            }
          });
        }
      });
    },
    //微信支付
    weixinPayPlugin (sn) {
      var obj2 = {};
      obj2.payment_plugin_id = 'weixinPayPlugin';
      obj2.client_type = 'PC';
      obj2.pay_mode = 'ar';
      this.showPayBox = true;
      // 支付宝、微信支付
      this.$nextTick(() => {
        document.getElementById(
          'pay-qrcode'
        ).innerHTML = `<iframe id="iframe-qrcode" width="200px" height="200px" scrolling="no" frameborder="0"></iframe>`;
        // 如果是普通支付方式，
        API_account.paymentServiceOrder(sn, obj2).then((response) => {
          console.log(response.gateway_url);
          this.gateway_url = response.gateway_url;
          let $formItems = '';
          response.form_items &&
            response.form_items.forEach((item) => {
              $formItems += `<input name="${item.item_name}" type="hidden" value='${item.item_value}' />`;
            });
          $formItems += `<input name="path" type="hidden" value='/finance/enterprise-overview' />`;
          let $form = `<form action="${response.gateway_url}" method="post">${$formItems}</form>`;
          const qrIframe = document.getElementById('iframe-qrcode')
            .contentWindow.document;
          qrIframe.getElementsByTagName('body')[0].innerHTML = $form;
          qrIframe.forms[0].submit();
        });
      });
    },
    //余额支付
    shopBalancePlugin (sn) {
      let order_params = {
        payment_plugin_id: 'shopBalancePlugin',
        pay_mode: 'normal',
        client_type: 'PC',
        trade_type: 'service',
      };
      API_account.paymentServiceOrder(sn, order_params).then((res) => {
        console.log(res, 'this.order_params');
        let callBackParams = {
          pay_order_no: res.pay_order_no,
          need_pay_money: res.need_pay_money,
          out_trade_no: res.out_trade_no,
        };
        API_account.callBack(res.call_back_url, callBackParams).then((resp) => {
          this.isShowDisRebate = false;
          this.$message.success('支付成功');
        });
      });
    },
    //线下支付
    payDown (sn) {
      let callBackParams = {
        pay_img: this.disRebateData.pay_img,
      };
      API_account.payDown(callBackParams, sn).then((res) => {
        this.isShowDisRebate = false;
        this.$message.success('操作成功');
      });
    },
    getAcountMoney () {
      API_account.getShopBalance().then((response) => {
        console.log(response, 'response');
        this.detail = response;
      });
    },
    daterangeDateChange (val) {
      if (
        this.daterangeDate &&
        this.daterangeDate.length &&
        this.daterangeDate.length > 0
      ) {
        this.params2.create_start_time = parseInt(this.daterangeDate[0] / 1000);
        this.params2.create_end_time = parseInt(this.daterangeDate[1] / 1000);
      }
    },
    daterangeDateChange1 (val) {
      if (this.dateEnd && this.dateEnd.length && this.dateEnd.length > 0) {
        this.params2.complete_start_time = parseInt(this.dateEnd[0] / 1000);
        this.params2.complete_end_time = parseInt(this.dateEnd[1] / 1000);
      }
    },

    submitImport () {
      this.importLoading = true;
      API_account.getSettleOrderList({}).then((response) => {
        let nameLabel =
          this.shopInfo.shop_type != 2 ? '供应商名称' : '店铺名称';
        let nameValue =
          this.shopInfo.shop_type != 2 ? 'seller_name' : 'shop_name';
        let priceValue =
          this.shopInfo.shop_type == 2 ? 'order_price' : 'shop_order_price';
        let pointValue =
          this.shopInfo.shop_type == 2 ? 'consume_point' : 'shop_consume_point';
        response.forEach((item) => {
          item.complete_time = Foundation.unixToDate(
            item.complete_time,
            'yyyy-MM-dd hh:mm'
          );
          if (item[pointValue]) {
            item[priceValue] =
              item[priceValue] + ' + ' + item[pointValue] + '积分';
          }
        });
        let tHeaders = [
          nameLabel,
          '订单号',
          '买家会员号',
          '订单金额',
          '快递单号',
          '快递名称',
          '订单日期',
        ];
        let filterVals = [
          nameValue,
          'sn',
          'member_name',
          priceValue,
          'ship_no',
          'logi_name',
          'complete_time',
        ];
        handleDownload(response, tHeaders, filterVals, '结算订单列表');
        this.importLoading = false;
      });
    },

    /** 获取物流公司信息*/
    GET_logisticsList () {
      API_logistics.getExpressCompanyList({}).then((response) => {
        this.logiList = response;
      });
    },

    /** 分页大小发生改变 */
    handlePageSizeChange (size) {
      this.params.page_size = size;
    },

    handlePageSizeChange1 (size) {
      this.params2.page_size = size;
      this.txHistList();
    },
    /** 分页页数发生改变 */
    handlePageCurrentChange (page) {
      this.params.page_no = page;
    },
    handlePageCurrentChange1 (page) {
      this.params2.page_no = page;
      this.txHistList();
    },

    /** 搜索事件触发 */
    searchEvent (data) {
      this.params = {
        ...this.params,
        keyword: data,
      };
    },

    handleSelectionChange (val) {
      let ids = [];
      val.forEach((item) => {
        ids.push(item.order_id);
      });
      this.idsList = ids;
    },
    settlement () {
      if (this.idsList.length) {
        API_account.checkaccountAdd({
          orderId: this.idsList,
          caDirection: this.params.ca_type,
        }).then((res) => {
          this.$message.success('发起结算成功');
        });
      } else {
        this.$message.error('请勾选数据');
      }
    },
    handleStockGoods (row) {
      this.$router.push('/order/detail/' + row.sn);
    },
  },
};
</script>

<style lang="scss" scoped>
.pay-item {
  display: flex;
  justify-content: space-around;
}

.cursor {
  cursor: pointer;
}

.conditions {
  display: inline-block;
}

.hui {
  color: #ccc;
}

.hui span {
  color: #ccc;
}

.toolbar-btns p {
  margin: 0;
  line-height: 30px;
  margin-bottom: 15px;
  font-size: 14px;
}

.overviewTop {
  overflow: hidden;
}

.tipBox {}

/* .tipBox p {
  margin: 0;
  line-height: 18px;
  margin-bottom: 15px;
  font-size: 14px;
} */
.tipBox p {
  line-height: 24px;
  padding: 0;
  position: relative;
  padding-left: 18px;
  font-size: 24px;
  color: #393c41;
  margin-bottom: 30px;
}

.tipBox p:before {
  content: " ";
  display: block;
  width: 8px;
  height: 24px;
  background: #1a43a9;
  position: absolute;
  left: -20px;
  top: 0;
}

.tipBox .ye {
  color: #cc3300;
  font-size: 18px;
  line-height: 18px;
  margin-bottom: 15px;
}

.tipBox .right {
  float: right;
  font-size: 12px;
  color: #333;
}

.tipBox .right span {
  margin-right: 10px;
}

.tipBox .price {
  font-size: 36px;
  line-height: 36px;
  font-weight: 900;
}

.overviewCenter {
  background: #fff;
  padding: 20px;
  overflow: hidden;
  border-radius: 5px;
}

.overviewCenter .content {
  /* margin: 0;
  line-height: 36px;
  font-size: 16px;

  font-weight: normal; */
  margin-bottom: 10px;
  line-height: 23px;
  padding: 0;
  position: relative;
  padding-left: 18px;
  font-size: 20px;
  color: #393c41;
}

.overviewCenter .content:before {
  content: " ";
  display: block;
  width: 8px;
  height: 24px;
  background: #1a43a9;
  position: absolute;
  left: -20px;
  top: 0;
}

.overviewCenter .box {
  display: inline-block;
  width: 49%;
  margin-bottom: 10px;
  font-size: 14px;
}

.overviewCenter .box.first-child {
  float: left;
  border-right: 1px solid #eee;
}

.overviewCenter .box.last-child {
  float: right;
}

.overviewCenter .box>div {
  width: 75%;
  margin: auto;
}

.overviewCenter .box>div>p {
  display: flex;
  justify-content: space-between;
}

.overviewCenter .box>div>p>span {
  width: 25%;
  text-align: right;
}

.overviewBottom {
  overflow: hidden;
  margin-top: 10px;
}

.overviewBottom .box {
  float: left;
  background: #fff;
  padding: 30px 20px;
  margin-right: 2%;
  width: 32%;
  text-align: center;
  border-radius: 5px;
}

.overviewBottom .box img {
  display: block;
  margin: auto;
  height: 50px;
}

.overviewBottom .box h3 {
  font-size: 16px;
  color: #333333;
  margin: 0;
  line-height: 50px;
  font-weight: normal;
}

.linkAll {
  font-size: 14px;
  color: #999;
}

.overviewBottom .box a {
  font-size: 14px;
  color: #1a43a9;
  margin: 0 10px;
}

.span_font {
  font-weight: bolder;
}

.overviewBottom .box:last-child {
  margin-right: 0;
}

.addMealForm>>>.el-radio--small.is-bordered {
  height: 42px;
  padding: 0;
}

.addMealForm>>>.el-radio--small.is-bordered .el-radio__input {
  display: none;
}

.addMealForm>>>.el-radio--small.is-bordered .el-radio__label {
  padding-left: 0;
}

.addMealForm>>>.el-radio--small.is-bordered img {
  height: 40px;
}

.formTip {
  line-height: 20px;
  font-size: 12px;
  color: #999;
}

.yjTip {
  width: 400px;
  text-align: center;
  line-height: 50px;
  background: #eee;
  color: #333;
  font-size: 14px;
  margin: auto;
  margin-bottom: 30px;
}

.dialog-footer {
  text-align: right;
}

.conditions {
  display: inline-block;
  margin-right: 20px;
}

.conditions>>>.el-input {
  display: inline-block;
}

.conditions span {
  font-size: 14px;
  color: #606266;
}

.glMoney {
  border: 1px solid #eee;
  padding: 20px;
  margin-bottom: 20px;
  padding-bottom: 10px;
}

.glMoney .top {
  overflow: hidden;
}

.glMoney .top .left {
  float: left;
  width: 200px;
}

.glMoney .top .right {
  float: left;
  border-left: 1px solid #eee;
  padding-left: 70px;
}

.glMoney .top h3 {
  font-size: 16px;
  color: #999;
  font-weight: normal;
  margin: 0;
}

.glMoney .top h4 {
  font-size: 24px;
  color: #cc3300;
  font-weight: normal;
  margin: 0;
  line-height: 70px;
}

.glMoney .top .right h5 {
  font-size: 20px;
  font-weight: normal;
  line-height: 50px;
  color: #cc3300;
  margin: 0;
}

.glMoney .top .right h6 {
  font-size: 20px;
  font-weight: normal;
  line-height: 32px;
  color: #31ca8b;
  margin: 0;
  margin-top: 10px;
}

.glMoney .top .right .btnRight {
  float: right;
  margin-left: 30px;
  margin-top: -2px;
}

.glMoney .tip {
  margin-top: 10px;
}

.glMoney .tip p {
  color: #999;
}

.t1 {
  position: relative;
  top: -1px;
}

.txxqMoney {
  border: 1px solid #eee;
  padding: 20px;
  margin-bottom: 20px;
  overflow: hidden;
}

.txxqMoney .left {
  float: left;
}

.txxqMoney .right {
  float: right;
}

.txxqMoney p {
  margin: 0;
  line-height: 30px;
  height: 30px;
}

.site-logo-img {
  width: 100%;
}

.cashier-pay-box {
  border: 1px solid #e1e1e1;
  background: #f4f4f4;
  margin: 0 0 40px 0;
  padding-top: 3px;

  .pay-item {
    display: flex;
    justify-content: center;
    margin: 0 3px 3px 3px;
    background: #fff;
    overflow: hidden;
    height: 335px;

    .pay-left {
      width: 471px;
      float: left;
      height: 310px;
      position: relative;

      p {
        width: 450px;
        text-align: center;
        height: 30px;
        line-height: 30px;
        margin: 25px 0 0 0;
        font-size: 16px;
      }

      .pc-pay-img {
        height: 92px;
        margin-left: 150px;
        margin-top: 48px;
        width: 165px;
        background: url(../../assets/images/icons-cashier.png) no-repeat 0 -1417px;
      }

      .pay-btn {
        width: 180px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        color: #fff;
        font-size: 14px;
        background: #fff;
        display: block;
        margin: 30px auto 0 auto;
      }

      .icon-or {
        display: block;
        background: url(../../assets/images/icons-cashier.png) no-repeat -212px -1417px;
        height: 41px;
        left: 464px;
        position: absolute;
        top: 130px;
        width: 31px;
      }
    }

    .pay-right {
      float: left;
      border-left: 1px solid #f4f4f4;

      p {
        width: 472px;
        text-align: center;
        height: 30px;
        line-height: 30px;
        margin: 25px 0 0 0;
        font-size: 16px;
      }

      .pay-qrcode {
        margin: 20px auto;
        height: 200px;
        width: 200px;
        overflow: hidden;
      }
    }
  }
}
</style>
